.SubSteps_icon {
  background-color: #e3e3e3;
  color: #ffffff;
  border-radius: 50%;
  padding: 0 10px;

  &.isActive, &.isCompleted {
    background-color: #096bef;
  }
}

.SubSteps_iconContainer {
  padding-right: 0px;
}

.SubSteps_stepRoot {
  padding-left: 0px;
  padding-right: 0px;
}