.ChatBox_container {
  bottom: 48px;
  position: fixed;
  right: 48px;
}

.ChatBox_display {
  margin-top: 80px;
  max-height: 424px;
  overflow-y: auto;
}

.ChatBox_footer {
  background: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #EDF1F7;
  bottom: 16px;
  position: fixed;
  width: 100%;

  .inputFields {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 8px;

    .input {
      padding: 8px;
      width: 240px;
    }

    .sendMessage {
      align-items: center;
      background: linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      cursor: pointer;
      display: flex;
      height: 39px;
      justify-content: center;
      width: 40px;
    }

    .notchedOutline {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: unset;
      border-top-left-radius: 4px;
      border-top-right-radius: unset;
    }

    .root {
      padding: 0px 8px 1px,
    }
  }
}

.Chatbox_groupchat {
  cursor: pointer;

  .displayGroup {
    display: flex;
    padding: 8px;
  }

  .gcName {
    margin-left: 8px;
  }
}

.ChatBox_header {
  background-color: #FFFEDD;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #EDF1F7;
  height: 80px;
  position: fixed;
  width: 100%;

  .headerFlex {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0px 24px;
    justify-content: space-between;

    .back {
      cursor: pointer;
      margin-right: 8px;
      margin-top: 4px;
    }

    .close {
      cursor: pointer;
      display: flex;
      margin-right: 8px;
      margin-top: 4px;
    }

    .logo {
      margin-right: 8px;
    }
  
    .menu {
      display: flex;
    }

    .title {
      align-items: center;
      display: flex;
    }
  }
}

.Chatbox_message {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 70%;

  &.currentUser {
    margin-right: 8px;
  }

  .display {
    color: #8F9BB3;
    display: flex;
    &.currentUser {
      justify-content: flex-end;
    }
  }

  .inputs {
    background: #FFFEDD;
    border-radius: 8px;
    color: #010046;
    padding: 8px;
    &.currentUser {
      background: #F3F7FF;
      color: #010046;
    }
  }
}

.Chatbox_messageContainer {
  display: flex;
  margin: 8px 0px;
  &.currentUser {
    justify-content: flex-end;
  }
}

.ChatBox_section {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 8px 24px rgba(34, 43, 69, 0.08);
  display: flex;
  flex-direction: column;
  height: 560px;
  margin-bottom: 16px;
  max-height: 560px;
  position: relative;
  width: 400px;
}

.ChatBox_icon {
  cursor: pointer;
}