.PaginationPermits_item {
  border-radius: 16px;
  cursor: pointer;
  margin: 0px 16px;
  padding: 4px 8px;
  text-align: center;
  width: 16px;
  
  &.selected {
    background-color: rgba(0, 0, 0, 0.08);
  }
}