$sm: 600px;

.Home_cardContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: center;
  padding: 32px;
  position: relative;
  text-align: center;
}

.Home_header {
  align-items: center;
  display: flex;
  padding: 32px;
  height: 160px;
  justify-content: space-evenly;
}

.Home_permitCard {
  &.isHover {
    box-shadow: 0px 1px 5px 0px #096bef, 0px 1px 5px 0px #096bef, 0px 1px 5px 0px #096bef;
  }

  &.isLoggedIn {
    cursor: pointer;
  }
}

.Home_permitLabel {
  color: #010046;

  &.isHover {
    color: #096bef;
    font-style: italic;
  }
}

@media (max-width: $sm) {
  .Home_header {
    flex-direction: column;
  }
}