$sm: 600px;

.Footer_container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 64px;
}

.Footer_logo {
  align-items: center;
  display: flex;
}

@media (max-width: $sm) {
  .Footer_container {
    flex-direction: column;
    text-align: center;
  }

  .Footer_logo {
    margin-bottom: 24px;
  }
}