.Requirements_accordion {
  box-shadow: none;
}

.Requirements_accordionDetails {
  padding: 0;
}

.Requirements_fileLink {
  color: #010046;
  display: flex;
  text-decoration: none;

  &:hover {
    color: #096bef;
    text-decoration: underline;
    text-decoration-color: #096bef;
  }
}

.Requirements_fileLinkAdmin {
  color: #096bef;
  display: flex;
  text-decoration: underline;
  text-decoration-color: #096bef;
}

.Requirements_summary {
  border-bottom: 1px solid #0000001f;
  padding: 0;
}

.Requirements_upload {
  color: #096bef;
  cursor: pointer;
  font-style: italic;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #096bef;
}