$sm: 600px;

.NavigationBar_drawerLinks {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.NavigationBar_header {
  &.isScrolled {
    background-color: #ffffff;
    left: 0;
    position: sticky;
    top: 0;
    z-index: 20;
  }
}

.NavigationBar_links {
  border-bottom: 2px solid #ffffff;
  border-radius: 0;
  color: #010046;
  padding-bottom: 8px;
  text-decoration: none;

  &.isSelected {
    border-bottom: 2px solid #096bef;
    color: #096bef;
    font-style: italic;
  }
}

.NavigationBar_logo {
  align-items: center;
  display: flex;
  width: 30%;
}

.NavigationBar_logout {
  border-radius: 0;
  font-style: italic;
  text-align: center;
  text-transform: capitalize;
}

.NavigationBar_section {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 64px;
}

.NavigationBar_swipeableDrawer_root {
  top: 96px;
}

.NavigationBar_user {
  display: flex;
  flex-direction: row;
}

@media (max-width: $sm) {
  .NavigationBar_logo {
    width: auto;
  }

  .NavigationBar_section {
    padding: 24px;
  }
}