.Permits_menuItem {
  &:hover {
    background-color: #f0f6ff;
    color: #096bef;
    font-weight: 500;
  }
}

.Permits_select {
  background-color: #ffffff;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 0;
  padding-top: 8px;
}