$sm: 600px;

.ActivePermits_button {
  color: #096BEF;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    padding: 2px;
    background: linear-gradient(270deg, #77D2FF 0%, #489DFF 91.89%);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
  }
}

.ActivePermits_container {
  display: flex;
  flex-direction: column;
  padding: 40px 64px 240px;
}

.ActivePermits_header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media (max-width: $sm) {
}